<template>
  <div class="container">
    <h1 class="message">Under construction</h1>
  </div>
</template>

<style>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.message {
  font-size: 32px;
  text-align: center;
  color: #333;
}
</style>
